<template>
  <div class="feature-card col-lg-3">
    <span class="fa-stack fa-4x">
      <i class="fas fa-circle fa-stack-2x text-primary"></i>
      <i :class="'fas fa-stack-1x ' + icon + ' icon-features'"></i>
    </span>
    <h4 class="my-3 titulo">{{ title }}</h4>
    <p class="text-muted">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "FeatureCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    }
  },
}
</script>


<style lang="css" scoped>
.feature-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
}

.feature-card .fa-stack {
  flex-shrink: 0;
}

.feature-card .titulo {
  color: black;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.feature-card .text-muted {
  flex-grow: 1;
}

/* Optional: Add min-height to ensure consistent height */
.feature-card .titulo,
.feature-card .text-muted {
  min-height: 3rem; /* Adjust as needed */
}
</style>
