<template>
  <div class="section">
    <div class="container">
      <h2 class="text-center">{{ title }}</h2>
      <div class="video-container">
        <iframe width="800" height="450" :src="videoUrl" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoYtVue",
  props: {
    title: {
      type: String,
      required: true
    },
    videoId: {
      type: String,
      required: true
    }
  },
  computed: {
    videoUrl() {
      return `https://www.youtube.com/embed/${this.videoId}`;
    }
  }
};
</script>

<style scoped>
.section {
  padding: 40px;
}

.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide height by width) */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>