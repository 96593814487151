<template>
    <section class="carousel" id="carousel">
        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div v-for="(slide, index) in slides" :key="index"
                    :class="['carousel-item', { 'active': index === 0 }]">
                    <a :href="slide.link">
                        <img :src="slide.image" class="d-block w-100" :alt="slide.alt">
                    </a>
                    <div class="carousel-caption d-none d-md-block legenda">
                        <h5>{{ slide.title }}</h5>
                        <p>{{ slide.description }}</p>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </section>
</template>

<script>
export default {
    name: "CarouselVue",
    data() {
        return {
            slides: [
                {
                    image: 'images/slide4.png',
                    alt: 'Resultado',
                    title: '',
                    description: '',
                    link: 'https://www.educacao.ma.gov.br/seduc-divulga-resultado-das-inscricoes-de-candidatos-a-vagas-em-cursos-ofertados-pela-plataforma-maranhao-profissionalizado/'
                },
                {
                    image: 'images/slide1.png',
                    alt: 'First slide',
                    title: '',
                    description: '',
                    link: '#'
                },
                // {
                //     image: 'images/slide2.png',
                //     alt: 'Second Slide',
                //     title: '',
                //     description: ''
                // },
                {
                    image: 'images/slide3.png',
                    alt: 'Third slide',
                    title: '',
                    description: '',
                    link: '#cursos'
                }
            ]
        };
    }
};
</script>

<style lang="css" scoped>
.legenda {
    bottom: 12rem;
}

@media(max-width: 769px) {
    .carousel {
        margin-top: 10rem;
    }
}
</style>
