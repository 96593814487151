<template>
  <div>
    <NavVue />
    <Carousel />
    
    


    <!-- Sobre Section -->
    <section class="page-section" id="sobre">
      <SobreSection />
    </section>
    <!-- Features Section -->
    <section class="page-section bg-light" style="margin: 20px 0; padding: 20px 0;" id="services">
      <div class="container">
        <div class="text-center">
          <h2 class="section-heading text-uppercase" style="color: black;">Indicadores de Sucesso</h2>
        </div>
        <div class="row text-center align-items-stretch justify-center">
          <FeatureCard icon="fa-solid fa-graduation-cap"
            description="Certificado com selo institucional e com código para validação."
            title="Certificação Reconhecida" />
          <FeatureCard icon="fa-solid fa-clock" description="Aulas acessíveis de qualquer a lugar, a qualquer hora, ajustando-se à sua rotina.
          " title="Estudo Flexivel" />
          <FeatureCard icon="fa-solid fa-book-open-reader" description="Explore videoaulas, quizzes, fóruns e materiais de apoio para uma experiência de aprendizagem dinâmica." title="Aprendizagem Interativa" />
          <FeatureCard icon="fa-solid fa-address-card" description="Adquira habilidades valiosas, explore conteúdos envolventes e alcance o sucesso profissional." title="Educação Acessível e Gratuita" />
        </div>
      </div>
    </section>

    <VideoYt video-id="G8e1GZ0wFXY" title="Programa Maranhão Profissionalizado" />

    

    <!-- Cursos Section-->
    <section class="page-section bg-light" id="cursos">
      <div class="container">
        <div class="text-center">
          <h2 class="section-heading text-uppercase" style="color: black;">Cursos</h2>
          <h3 class="section-subheading text-muted">Conheça nossos cursos 👇</h3>
        </div>
        <div class="row">
          <div class="col-lg-4 col-sm-6 mb-4" v-for="course in paginatedCourses" :key="course.id">
            <CursoItem :curso="course" />
          </div>
        </div>
        <div class="pagination text-center">
          <button class="btn btn-secondary" @click="previousPage" :disabled="currentPage === 1">
            Página Anterior
          </button>
          <span class="page-counter">{{ currentPage }} / {{ totalPages }}</span>
          <button class="btn btn-secondary" @click="nextPage" :disabled="currentPage === totalPages">
            Próxima Página
          </button>
        </div>

      </div>
    </section>

    <section class="page-section" id="services">
      <div class="container">
        <div class="text-center">
          <h2 class="section-heading text-uppercase">Números que refletem confiança!</h2>
          <h3 class="section-subheading text-muted"></h3>
        </div>
        <div class="row text-center align-items-stretch justify-center">
          <MetricCard title="Matrículas Realizadas" :value=105306 />
          <MetricCard title="Horas de Conteúdo" :value=243 />
          <MetricCard title="Atividades Complementares" :value=15641 />
          <MetricCard title="Cursos Disponíveis" :value=38 />
        </div>
      </div>
    </section>

    <WhatsappButton />
    <FooterVue />
  </div>
</template>

<script>
// @ is an alias to /src
import NavVue from '@/components/Nav.vue'
import FooterVue from '@/components/Footer.vue'
import FeatureCard from '@/components/FeatureCard.vue'
import CursoItem from '@/components/CursoItem.vue'
import Carousel from '@/components/Carousel.vue'
import SobreSection from '@/components/SobreSection.vue'
import MetricCard from '@/components/MetricCard.vue'
import VideoYt from '@/components/VideoYt.vue'
import WhatsappButton from '@/components/WhatsappButton.vue'
//data
import courses from "@/data/courses"

export default {
  name: 'HomeView',
  components: {
    NavVue,
    FooterVue,
    Carousel,
    FeatureCard,
    CursoItem,
    SobreSection,
    MetricCard,
    WhatsappButton,
    VideoYt
  },
  data() {
    return {
      courses: courses,
      currentPage: 1,
      itemsPerPage: 9,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.courses.length / this.itemsPerPage);
    },
    paginatedCourses() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.courses.slice(startIndex, endIndex);
    },
  },
  methods: {
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },

}
</script>

<style>
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

.page-counter {
  margin: 0 10px;
  color: black;
}
</style>
