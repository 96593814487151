<template>
    <div class="container content">
        <div class="accordion primary" id="accordionExample">
            <div class="accordion-item" v-for="(faq, index) in faqs" :key="index">
                <h2 class="accordion-header">
                    <button class="accordion-button" type="button" :data-bs-toggle="'collapse'"
                        :data-bs-target="'#collapse' + index" :aria-expanded="index === 0 ? 'true' : 'false'"
                        :aria-controls="'collapse' + index">
                        {{ faq.question }}
                    </button>
                </h2>
                <div :id="'collapse' + index" class="accordion-collapse collapse" :class="{ 'show': index === 0 }"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        {{ faq.answer }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "FaqComponent",
    data() {
        return {
            "faqs": [
                {
                    "question": "Como acessar a Plataforma Maranhão Profissionalizado?",
                    "answer": "Para acessar a Plataforma Maranhão Profissionalizado, você precisa seguir alguns passos simples. Primeiro, abra seu navegador de preferência e acesse o site oficial da Plataforma (https://www.maranhaoprofissionalizado.iema.ma.gov.br). Em seguida, procure pela opção de LOGIN e clique nela. Na página de login, você precisará informar seu CPF e a Senha de Acesso que foi cadastrada. Após preencher esses dados, clique em 'Entrar' para acessar a Plataforma."
                },
                {
                    "question": "Como recuperar a senha de acesso à Plataforma Maranhão Profissionalizado?",
                    "answer": "Para recuperar a senha de acesso à Plataforma Maranhão Profissionalizado, siga esses passos simples. Acesse o site oficial da Plataforma e clique na opção de login. Na página seguinte, você verá a opção 'RECUPERAR SUA SENHA'. Clique nessa opção e siga as instruções fornecidas. Você precisará informar seu CPF e endereço de E-MAIL cadastrado. Em seguida, você receberá um e-mail com instruções para redefinir sua senha. Siga as orientações fornecidas no e-mail para criar uma nova senha e pronto! Agora você poderá acessar a plataforma utilizando sua nova senha."
                },
                {
                    "question": "Quais documentos são necessários para efetivar a matrícula na Plataforma Maranhão Profissionalizado?",
                    "answer": "Para efetivar a matrícula na Plataforma Maranhão Profissionalizado, NÃO SÃO NECESSÁRIOS DOCUMENTOS ADICIONAIS. Basta acessar a Plataforma utilizando seu CPF e senha cadastrados. É importante verificar com atenção os dados inseridos durante o processo de inscrição para evitar possíveis erros. Certifique-se de utilizar informações corretas e atualizadas."
                },
                {
                    "question": "O que fazer se eu tiver dificuldades para acessar a Plataforma Maranhão Profissionalizado?",
                    "answer": "Caso você tenha realizado o cadastro na Plataforma Maranhão Profissionalizado, mas esteja enfrentando dificuldades para acessá-la, recomendamos que entre em contato conosco pelo e-mail suporte.ead@edu.ma.gov.br ou pelo telefone (98) 98506-4913. Informe-nos sobre o problema que está enfrentando, e nossa equipe terá o prazer de orientá-lo(a) e buscar uma solução para que você possa acessar a plataforma com sucesso."
                },
                {
                    "question": "Posso me inscrever em mais de um curso na Plataforma Maranhão Profissionalizado?",
                    "answer": "Sim, você pode se inscrever em mais de um curso na Plataforma Maranhão Profissionalizado. A plataforma permite que você escolha e se matricule em diversos cursos disponíveis, de acordo com o seu interesse e disponibilidade. No entanto, é importante lembrar que a matrícula em cada curso está sujeita à disponibilidade de vagas. Portanto, mesmo que você se inscreva em mais de um curso, a efetivação da matrícula dependerá do número de vagas disponíveis para cada curso. Certifique-se de verificar os critérios e prazos específicos de cada curso antes de realizar a inscrição."
                },
                {
                    "question": "Como funciona o teste de nivelamento para os Cursos de Língua Inglesa e Língua Espanhola?",
                    "answer": "O teste de nivelamento é uma etapa fundamental para determinar o módulo adequado para os candidatos aos Cursos de Língua Inglesa e Língua Espanhola. Durante a inscrição online, aqueles que NÃO OPTAREM pelo Módulo Básico I serão direcionados para o teste, composto por questões objetivas. No caso do teste de Inglês, são 120 questões, enquanto o de Espanhol conta com 40 perguntas. A classificação nos diferentes módulos é baseada na quantidade de acertos, determinando o grau de proficiência do estudante. É importante observar que o teste serve apenas para alocar os candidatos em turmas correspondentes ao seu nível de conhecimento, sem conceder direito a atestados ou certificações externas."
                },
                {
                    "question": "Como posso alterar meus dados cadastrais na Plataforma Maranhão Profissionalizado?",
                    "answer": "Para alterar seus dados cadastrais na Plataforma Maranhão Profissionalizado, você pode entrar em contato conosco através do e-mail suporte.ead@edu.ma.gov.br ou pelo WhatsApp no número (98) 98506-4913. Informe-nos quais dados deseja alterar, como nome, CPF, telefone, entre outros. Nossa equipe ficará responsável por realizar as devidas atualizações em seu cadastro. Lembre-se de fornecer as informações corretas e completas para agilizar o processo de alteração."
                },
                {
                    "question": "Como acompanhar o meu progresso no curso?",
                    "answer": "Para acompanhar o seu progresso no curso, basta acessar a opção 'Progresso no Curso' no menu lateral da plataforma. Ao clicar nessa opção, você poderá visualizar os módulos, aulas e demais atividades que estão pendentes para completar o seu percurso formativo. Lembre-se de que, para concluir o curso e receber o certificado, é necessário ter completado pelo menos 70% do conteúdo e atingido a nota mínima de 7,0 nos momentos avaliativos. Essa funcionalidade permitirá que você acompanhe de forma clara o seu avanço e saiba quais etapas ainda precisam ser concluídas."
                },
                {
                    "question": "Quais são os requisitos para obter o certificado de conclusão do curso?",
                    "answer": "O curso é composto por videoaulas, materiais complementares e momentos avaliativos, bem como a participação nos fóruns, que são obrigatórios. Para obter o certificado de conclusão do curso, é necessário atender aos seguintes requisitos: obter a nota mínima de 7,0 nos momentos avaliativos e ter uma frequência mínima de 70% no curso. Recomendamos que você acompanhe todo o percurso formativo, assistindo a todas as aulas e participando ativamente das atividades, a fim de garantir um melhor aproveitamento do conteúdo e uma formação mais completa."
                },
                {
                    "question": "Qual é o prazo limite para finalizar o curso?",
                    "answer": "Sim, existe um prazo limite para finalizar o curso, que é de TRÊS MESES A PARTIR DA DATA DE EFETIVAÇÃO DA MATRÍCULA. É importante ressaltar que os alunos serão devidamente informados sobre o prazo restante para conclusão dos cursos nos quais estão inscritos, para que possam se organizar e garantir a finalização dentro do prazo estabelecido. Recomendamos que você acompanhe o seu progresso no curso e se planeje para concluir as atividades dentro do prazo determinado."
                },
                {
                    "question": "Posso acessar a plataforma em dispositivos móveis?",
                    "answer": "Sim, você pode acessar a plataforma em dispositivos móveis, como smartphones ou tablets. O layout da plataforma é responsivo, o que significa que ele se adapta automaticamente ao tamanho da tela do seu dispositivo. No entanto, é importante observar que pode haver algumas diferenças na exibição e na organização das informações devido à resolução da tela."
                },
                {
                    "question": "Como são avaliados os cursos da Plataforma Maranhão Profissionalizado?",
                    "answer": "Os cursos são avaliados de diferentes formas para garantir seu progresso e aprendizado. Sua participação nos fóruns é importante, pois os tutores validarão sua interação e contribuição. Além disso, você terá momentos avaliativos, como quizzes com perguntas objetivas que abrangem todo o conteúdo do curso. Também é fundamental acessar as videoaulas e materiais complementares, pois eles fornecem informações valiosas para seu aprendizado. Essas atividades combinadas ajudarão a avaliar seu desempenho e progresso ao longo do curso."
                },
                {
                    "question": "Quais são os requisitos para participar dos cursos da Plataforma Maranhão Profissionalizado?",
                    "answer": "Para participar dos cursos da Plataforma Maranhão Profissionalizado, verifique os requisitos em cada edital. É necessário atender aos critérios estabelecidos, como idade mínima e escolaridade. Além disso, tenha um dispositivo com acesso à internet para assistir às aulas. Verifique a estabilidade da conexão e certifique-se de que seu dispositivo esteja atualizado."
                },
                {
                    "question": "Existe suporte técnico disponível para os usuários da Plataforma Maranhão Profissionalizado?",
                    "answer": "Sim, estamos empenhados em oferecer um suporte técnico de qualidade. Caso você enfrente algum problema com a plataforma, temos uma equipe disponível para ajudar. Você pode entrar em contato conosco através do e-mail suporte.ead@edu.ma.gov.br ou pelo telefone (98) 98506-4913 para obter assistência e solucionar qualquer dificuldade que possa surgir. Estamos comprometidos em garantir a melhor experiência possível aos nossos usuários e em resolver quaisquer problemas técnicos que possam surgir."
                },
                {
                    "question": "Posso interromper meu progresso em um curso e retomá-lo posteriormente?",
                    "answer": "Sim, você pode interromper seu progresso em um curso e retomá-lo posteriormente. A plataforma Maranhão Profissionalizado permite que você tenha flexibilidade em relação ao ritmo de estudos. Se precisar interromper o curso por qualquer motivo, você poderá retomá-lo de onde parou quando estiver pronto. A plataforma salvará o seu progresso e você poderá continuar exatamente de onde parou, sem perder nenhum conteúdo ou atividade já realizada. É importante destacar que existe um prazo limite para sua conclusão. Após efetivar a matrícula, você terá um período de até três meses para finalizar o curso."
                },
                {
                    "question": "Os cursos da Plataforma Maranhão Profissionalizado concedem certificação reconhecida?",
                    "answer": "Sim, todos os cursos oferecidos pela plataforma Maranhão Profissionalizado concedem certificação reconhecida. Essa certificação, emitida pelo IEMA (Instituto Estadual de Educação, Ciência e Tecnologia do Maranhão), é válida e reconhecida, proporcionando respaldo e comprovação do conhecimento adquirido durante o curso. É importante observar os critérios para obtenção do certificado, que incluem a conclusão de 70% do curso e a obtenção de uma nota mínima de 7,0 nas avaliações."
                }
            ]
        };
    }
}
</script>

<style lang="css" scoped>
.content {
    margin-top: 10rem;
}

.accordion {
    color: #fff;
    background-color: #333;
}

.accordion-button {
    color: #fff;
    background-color: #555;
    border: 1px solid #444;
}

.accordion-button:hover {
    background-color: #777;
}

.accordion-item {
    border: none;
}

.accordion-collapse {
    background-color: #444;
}

.accordion-body {
    color: #ccc;
}
</style>