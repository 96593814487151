<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
            <div class="container">
                <router-link to="/" class="navbar-brand"><img src="images/logo.png" alt="logo Maranhão Profissionalizado" /></router-link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <i class="fas fa-bars ms-1"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
                        <li class="nav-item"><router-link to="/" class="nav-link">Home</router-link></li>
                        <li class="nav-item"><router-link to="/faq" class="nav-link">Dúvidas Frequentes</router-link></li>
                        <li class="nav-item"><a class="nav-link" href="#sobre" @click="navigateToAbout">Sobre</a></li>
                        <li class="nav-item"><a class="nav-link" href="#cursos" @click="navigateToCourses">Cursos</a></li>
                        <li class="nav-item"><a class="nav-link" href="https://seletivos.iema.ma.gov.br/inscricao_maranhaoprofissionalizado/registro">Inscrição</a></li> 
                        <li class="nav-item"><a class="nav-link" href="https://maranhaoprofissionalizado.iema.ma.gov.br/app/#/login/">Login</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: "NavVue",
    methods: {
        navigateToAbout() {
            if (this.$route.hash !== '#sobre') {
                this.$router.push({ path: '/', hash: 'sobre' });
            }
        },
        navigateToCourses() {
            if (this.$route.hash !== '#cursos') {
                this.$router.push({ path: '/', hash: 'cursos' });
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
