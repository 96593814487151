<template>
    <div>
        <!-- Footer-->
        <footer class="footer py-4">
            <div class="container">
                <div class="row align-items-center">
                    <img class="featurette-image img-fluid mx-auto" src="images/seduc_iema_logo.png" style="width: 300px;" alt="logo Governo do Maranhão | SEDUC | IEMA" />
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    export default {
        name: "FooterVue"
    }
</script>

<style lang="scss" scoped>

</style>